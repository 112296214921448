import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 bg-primary-900 text-gray-100`;

const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto text-center text-gray-300`;

const FaqsContainer = tw.div`mt-10 sm:mt-16 w-full flex-1 lg:flex justify-between items-start max-w-screen-lg mx-auto`;
const FaqsColumn = tw.div`w-full lg:max-w-lg lg:mr-12 last:mr-0`;
const Faq = tw.div`select-none cursor-pointer border-b-2 border-primary-300 hover:border-primary-500 transition-colors duration-300 py-6`;
const Question = tw.div`flex justify-between items-center`;
const QuestionText = tw.div`text-sm sm:text-lg font-semibold tracking-wide`;
const QuestionToggleIcon = styled(motion.span)`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;

// Styled component for email links
const EmailLink = styled.a`
  color: #FFA500; /* Orange color */
  transition: color 0.3s ease-in-out;

  &:hover {
    color: #FF4500; /* Darker shade of orange on hover */
    text-decoration: underline; /* Underline the link on hover */
  }
`;

const Answer = tw(motion.div)`hidden text-sm font-normal mt-4 text-gray-300`;

export default ({
  subheading = "",
  heading = "Frequently Asked Questions",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  faqs = [
    {
      question: "What kind of tech products does your company specialize in?",
      answer:
        "We specialize in turnkey ecommerce solutions, networking platforms, industrial/single-store ERP software, and various business management softwares. Additionally, we undertake projects to create new tech products using cutting-edge technology stacks."
    },
    {
      question: "Is your company currently hiring?",
      answer:
        "Yes, we are always on the lookout for top talent to join our team! Candidates are encouraged to drop their CVs at rahin@gorillamove.com."
    },
    {
      question: "What technologies do you use to develop your products?",
      answer: "We leverage the latest generation technology stack to develop and maintain our products, ensuring they are robust, scalable, and future-proof. Our team is skilled in a variety of technologies to meet the diverse needs of our clients. Our high-performing projects are currently built using GoLang, ReactJS, React-Native, NodeJS, and hosted on AWS with Docker, Kubernetes, and AWS Lambda. We implement gRPC for efficient communication and maintain stringent security measures to safeguard our solutions."
    },
    {
      question: "Do you cater to both local and international markets?",
      answer:
        "Absolutely! Our tech solutions are designed to serve the needs of both businesses all around the globe."
    },
    {
      question: "Can you provide more information about your turnkey ecommerce solutions?",
      answer:
        "Our turnkey ecommerce solutions are designed to provide businesses with everything they need to start and manage an online store with single or multiple fulfillment branches and inventory locations. This includes website and mobile application design and development, CRM, analytics, payment gateways integration, last-mile delivery system and 3rd-party-logistics integration, inventory management and reporting, accounting integration, and much more."
    },
    {
      question: "How can I get in touch?",
      answer: (
        <span>
          You can reach out to us by sending an email to{' '}
          <EmailLink href="mailto:rahin@gorillamove.com">rahin@gorillamove.com</EmailLink> or{' '}
          <EmailLink href="mailto:shawkat@gorillamove.com">shawkat@gorillamove.com</EmailLink> for general inquiries,
          partnership opportunities, or to discuss your project requirements. We're always eager to connect with you and explore how we can collaborate to meet your tech needs!
        </span>
      )
    }
  ]
}) => {
  const faqCol1 = [];
  const faqCol2 = [];
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  faqs.map((faq, index) => {
    const renderedFaq = (
      <Faq key={index} onClick={() => toggleQuestion(index)}>
        <Question>
          <QuestionText>{faq.question}</QuestionText>
          <QuestionToggleIcon
            variants={{
              collapsed: { rotate: 0 },
              open: { rotate: -180 }
            }}
            initial="collapsed"
            animate={activeQuestionIndex === index ? "open" : "collapsed"}
            transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ChevronDownIcon />
          </QuestionToggleIcon>
        </Question>
        <Answer
          variants={{
            open: { opacity: 1, height: "auto", marginTop: "16px", display: "block" },
            collapsed: { opacity: 0, height: 0, marginTop: "0px", display: "none" }
          }}
          initial="collapsed"
          animate={activeQuestionIndex === index ? "open" : "collapsed"}
          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {faq.answer}
        </Answer>
      </Faq>
    );

    if (index % 2 === 0) faqCol1.push(renderedFaq);
    else faqCol2.push(renderedFaq);

    return null;
  });
  return (
    <PrimaryBackgroundContainer>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {/* <Description>{description}</Description> */}
        </HeadingContainer>
        <FaqsContainer>
          <FaqsColumn>{faqCol1}</FaqsColumn>
          <FaqsColumn>{faqCol2}</FaqsColumn>
        </FaqsContainer>
      </ContentWithPaddingXl>
    </PrimaryBackgroundContainer>
  );
};
