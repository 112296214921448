import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import HeaderBase, {
  LogoLink as LogoLinkBase,
  NavLinks,
  NavLink as NavLinkBase,
  PrimaryLink as PrimaryLinkBase
} from "../headers/light.js";
import { Container as ContainerBase, ContentWithVerticalPadding, Content2Xl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import logoImageSrc from "images/gononet_header_white.png";
import serverIllustrationImageSrc from "images/ios_rocket.png";
import { keyframes } from "styled-components";
// const PrimaryBackgroundContainer = tw.div`-mx-8 px-8 bg-primary-900 text-gray-100`;

const PrimaryBackgroundContainer = tw.div`-mx-8 px-8 bg-gradient-to-t from-gononet-200 to-gononet-300 text-gray-100`;

// const PrimaryBackgroundContainer = tw.div`-mx-8 px-8`;
// const GradientBackground = tw.div`bg-gradient-to-br from-gononet-200 to-gononet-100 text-gray-100`;
// const PrimaryBackgroundWithGradient = tw(PrimaryBackgroundContainer)`text-gray-100`;

const shakeAnimation = keyframes`
  0% { transform: translate(0, 0) rotate(0); }
  10%, 30%, 50%, 70%, 90% { transform: translate(0, -10px) rotate(-2deg); }
  20%, 40%, 60%, 80%, 100% { transform: translate(0, -10px) rotate(2deg); }
`;




const Header = tw(HeaderBase)`max-w-none -mt-8 py-8 -mx-8 px-8`;
const NavLink = tw(NavLinkBase)`lg:text-gray-100 lg:hocus:text-gray-300 lg:hocus:border-gononet-100`;
const LogoLink = tw(LogoLinkBase)`text-gray-100 hocus:text-gray-300`;
const PrimaryLink = tw(PrimaryLinkBase)`shadow-raised lg:bg-primary-400 lg:hocus:bg-primary-500`;

const Container = tw(ContainerBase)``;
const Row = tw.div`flex items-center flex-col lg:flex-row`;
const Column = tw.div`lg:w-1/2`;
const TextColumn = tw.div`text-center lg:text-left`;
const IllustrationColumn = tw(Column)`mt-16 lg:mt-0 lg:ml-16`;
const Heading = tw(SectionHeading)`max-w-3xl lg:max-w-4xl lg:text-left leading-tight`;
const Description = tw(SectionDescription)`mt-4 max-w-2xl text-gray-100 lg:text-base mx-auto lg:mx-0`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-8 text-sm sm:text-base px-6 py-5 sm:px-10 sm:py-5 bg-primary-400 inline-block hocus:bg-primary-500`;
const Image = tw.img`w-144 ml-auto`

const ShakeImage = tw(Image)`w-144 ml-auto animate-shake`;

export default ({
  heading = "Creating inclusive software products for the masses.",
  description = "We design state-of-the-art software products to improve outcomes and value for audiences often overlooked.",
  primaryButtonText = "",
  primaryButtonUrl = "#",
  imageSrc = serverIllustrationImageSrc,
}) => {
  const logoLink = (
    <LogoLink href="/">
      <img src={logoImageSrc} alt="Logo" />
      
    </LogoLink>
  );
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/about-us">About us</NavLink>
      <NavLink href="/contact-us">Contact us</NavLink>
      {/* <NavLink href="#">Login</NavLink> */}
      {/* <PrimaryLink href="#">Signup</PrimaryLink> */}
    </NavLinks>
  ];
  return (
    <PrimaryBackgroundContainer style={{ backgroundSize: "300% auto" }}>
          {/* <GradientBackground> */}
      <Content2Xl>
        <Header logoLink={logoLink} links={navLinks} />
        <Container>
          <ContentWithVerticalPadding>
            <Row>
              <TextColumn>
                <Heading>{heading}</Heading>
                <Description>{description}</Description>
                {/* <PrimaryButton as="a" href={primaryButtonUrl}>{primaryButtonText}</PrimaryButton> */}
              </TextColumn>
              <IllustrationColumn>
                <ShakeImage src={imageSrc} />
              </IllustrationColumn>
            </Row>
          </ContentWithVerticalPadding>
        </Container>
      </Content2Xl>
      {/* </GradientBackground> */}
    </PrimaryBackgroundContainer>
  );
};
