import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import MainFeature from "components/features/TwoColWithButton.js";
import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
import Testimonial from "components/testimonials/SimplePrimaryBackground.js";
import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import serverRedundancyIllustrationImageSrc from "images/server-redundancy-illustration.svg"
// import serverSecureIllustrationImageSrc from "images/server-secure-illustration.svg"
import gorillajobsFeature from "images/gorillajobs_web_mockup_2_900.png"
import gorillamoveFeature from "images/gorillamove_erp_web_feature.png"


export default () => {
  return (
    <AnimationRevealPage>
      <Hero />
      <Features />
      {/* <Pricing  
      /> */}
      <MainFeature
        subheading="Turnkey e-Commerce Solution"
        // heading="Championing efficiency and speed with Gorillamove."
        heading={
          <span>
            Championing efficiency and speed with{" "}
            <span style={{ backgroundColor: "#39FF14", padding: "0 5px", borderRadius: "3px" }}>
              <span style={{ color: "#000000" }}>
                Gorillamove
              </span>
            </span>
            .
          </span>
        }
        description="Gorillamove is a fresh grocery delivery platform catering to over 80,000 customers in Bangladesh. The full-stack e-Commerce solution includes iOS/Android and Web customer apps, Pick, Pack, and Delivery app, and integrated ERP software — built primarily on a GoLang, ReactJS, React-Native technology stack."
        imageSrc={gorillamoveFeature}
        buttonRounded={false}
        primaryButtonText="Visit Gorillamove"
        primaryButtonUrl="https://www.gorillamove.com"
      />
      <MainFeature
        subheading="Networking Platform"
        // heading="Connecting talent & opportunities through Gorillajobs."
        heading={
          <span>
            Connecting talent & opportunities through{" "}
            <span style={{ backgroundColor: "#000000", padding: "0 5px", borderRadius: "3px" }}>
              <span style={{ color: "#39FF14" }}>
                Gorillajobs
              </span>
            </span>
            .
          </span>
        }
        description="Gorillajobs fosters job connections in Bangladesh. Jobseekers create CV-profiles, access curated listings, and refine profiles with AI. Employers post jobs, and connect instantly with top talent directly on the mobile and web platforms — built primarily on a GoLang, ReactJS, NodeJS, React-Native stack."
        imageSrc={gorillajobsFeature}
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Visit Gorillajobs"
        primaryButtonUrl="https://www.gorillajobs.app"
      />
      <MainFeature
        subheading="Next-generation ERP Software"
        heading="Twenty-first-century ERP for new businesses."
        description="Deploying the next-generation of ERP Software to high-performance businesses around the globe. Built on a blazing-fast lightweight tech stack, with features rivalling industry-leaders for unparalleled speed, security, and efficiency in your business. Intuitive modern UI with single or multi-store applications, notably including support for multiple inventory levels and locations, multiple currency, bin management, AI-integrated demand forecasting, and much more."
        imageSrc={serverRedundancyIllustrationImageSrc}
        buttonRounded={false}
        primaryButtonText="Contact us"
        primaryButtonUrl="mailto:rahin@gorillamove.com"
      />
      {/* <Testimonial 
      /> */}
      <FAQ />
      <Footer />
    </AnimationRevealPage>
  );
}
